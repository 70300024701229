import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import HttpService from "../common/services/http.service";
import { from, switchMap, tap } from "rxjs";
import AlertService from "../common/services/alert.service";
import ApiService from "../common/services/api.service";
import { DoctorService } from "../common/services/doctor.service";
const AuthContext = React.createContext();

export class AuthProvider extends Component {
  // used to check every 2 minutes if the token did not expire
  _interval = null;
  state = {
    isAuthenticated: false,
    userWithRolesAndToken: null,
    doctor: null,
    user: null,
  };

  componentWillUnmount() {
    clearInterval(this._interval);
  }
  // used to authenticate user who is logged in and has to confirm some decision
  checkPassword = (password) => {
    return from(
      HttpService.post("user/authenticate", {
        password,
        email: this.state.user.email,
      })
    );
  };

  logIn = (formValues) => {
    const authenticateUser$ = from(
      HttpService.post("user/authenticate", formValues)
    );

    authenticateUser$
      .pipe(
        switchMap((userWithToken) => {
          // save the token to use it in further http calls
          localStorage.setItem("token", JSON.stringify(userWithToken.token));
          this.setState({
            userWithRolesAndToken: {
              roles: userWithToken.roles,
              token: JSON.stringify(userWithToken.token),
            },
          });
          // fetch details of user - email, id, roles
          return from(HttpService.get(`user/current`));
        }),
        tap((currentUser) => {
          if (currentUser.roles.includes("DOCTOR")) {
            // fetch doctor details: firstName, lastName, specializations etc
            ApiService.getDoctorAccount(currentUser.id).subscribe({
              next: (doctor) => {
                DoctorService.createSubject();
                DoctorService.doctor$.next(doctor);
                this.setState({ doctor });
                localStorage.setItem("doctor", JSON.stringify(doctor));
              },
              error: () => {
                AlertService.error("Nie można pobrać Twoich danych");
              },
            });
          }
        })
      )
      .subscribe({
        next: (currentUser) => {
          const userWithRolesAndToken = this.state.userWithRolesAndToken;
          // save all info on user in the state - it will be passed to context
          this.setState({
            user: {
              ...userWithRolesAndToken,
              ...currentUser,
            },
            isAuthenticated: true,
          });
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...userWithRolesAndToken,
              ...currentUser,
            })
          );
          this.initTokenChecking();
          AlertService.info(<div>Opiekunowie naszych Pacjentów!<br /><br />Jesteśmy w trakcie testowania nowego systemu telefonicznego.<br /><br />W związku z tym mogą wystąpić chwilowe trudności z dodzwonieniem się do naszej przychodni.<br /><br />Prosimy o kontakt mailowy na adres: kontakt@vivawet.pl<br /><br />Dziękujemy za wyrozumiałość!</div>)
         },
        error: (e) => {
          if (e.response?.data?.includes("user is not activated")) {
            // if user is not activated, they have to click the link in the email they received
            AlertService.error("Żeby aktywować konto, kliknij link w mailu");
          } else {
            AlertService.error("Błąd logowania!");
          }
        },
      });
  };

  logOut = () => {
    // clear all info about the user from the browser
    DoctorService.removeSubject();
    if (this._interval) {
      clearInterval(this._interval);
    }
    this.setState({ user: null, isAuthenticated: false });
    localStorage.clear();
  };

  readUserFromStorage = () => {
    // when window is opened, check if data about user is saved in the browser
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    const userWithRolesAndToken = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    const doctor =
      localStorage.getItem("doctor") !== "undefined"
        ? JSON.parse(localStorage.getItem("doctor"))
        : null;
    if (!token || !userWithRolesAndToken) {
      return;
    }

    if (doctor) {
      try {
        DoctorService.doctor$.next(doctor);
      } catch (_) {
        DoctorService.createSubject();
        DoctorService.doctor$.next(doctor);
      }
    }
    // if token is not expired, mark user as logged in
    if (jwt_decode(token).exp > Date.now() / 1000) {
      this.setState({
        user: userWithRolesAndToken,
        isAuthenticated: true,
        doctor,
      });
      this.initTokenChecking();
    }
  };

  initTokenChecking = () => {
    this._interval = setInterval(() => {
      if (!localStorage.getItem("token")) {
        this.logOut();
      }
      const token = JSON.parse(localStorage.getItem("token"));
      if (!token) {
        this.logOut();
        return;
      }
      if (jwt_decode(token).exp < Date.now() / 1000) {
        this.logOut();
      }
    }, 2 * 60 * 1000);
  };

  render() {
    const { user, isAuthenticated, doctor } = this.state;
    const {
      logIn,
      logOut,
      initTokenChecking,
      readUserFromStorage,
      checkPassword,
    } = this;
    return (
      <AuthContext.Provider
        value={{
          user,
          isAuthenticated,
          doctor,
          logIn,
          logOut,
          initTokenChecking,
          readUserFromStorage,
          checkPassword,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
export default AuthContext;
